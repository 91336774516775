// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akcesoria-js": () => import("./../../../src/pages/akcesoria.js" /* webpackChunkName: "component---src-pages-akcesoria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-onas-erreplus-js": () => import("./../../../src/pages/onas/erreplus.js" /* webpackChunkName: "component---src-pages-onas-erreplus-js" */),
  "component---src-pages-onas-fitandride-js": () => import("./../../../src/pages/onas/fitandride.js" /* webpackChunkName: "component---src-pages-onas-fitandride-js" */),
  "component---src-pages-onas-personalizacja-js": () => import("./../../../src/pages/onas/personalizacja.js" /* webpackChunkName: "component---src-pages-onas-personalizacja-js" */),
  "component---src-pages-onas-technologie-js": () => import("./../../../src/pages/onas/technologie.js" /* webpackChunkName: "component---src-pages-onas-technologie-js" */),
  "component---src-pages-saddlefitting-pasowanie-siodel-js": () => import("./../../../src/pages/saddlefitting/pasowanie-siodel.js" /* webpackChunkName: "component---src-pages-saddlefitting-pasowanie-siodel-js" */),
  "component---src-pages-saddlefitting-testy-js": () => import("./../../../src/pages/saddlefitting/testy.js" /* webpackChunkName: "component---src-pages-saddlefitting-testy-js" */),
  "component---src-pages-siodla-js": () => import("./../../../src/pages/siodla.js" /* webpackChunkName: "component---src-pages-siodla-js" */),
  "component---src-pages-szukaj-js": () => import("./../../../src/pages/szukaj.js" /* webpackChunkName: "component---src-pages-szukaj-js" */),
  "component---src-templates-accessory-type-js": () => import("./../../../src/templates/accessoryType.js" /* webpackChunkName: "component---src-templates-accessory-type-js" */),
  "component---src-templates-saddle-type-js": () => import("./../../../src/templates/saddleType.js" /* webpackChunkName: "component---src-templates-saddle-type-js" */),
  "component---src-templates-single-saddle-js": () => import("./../../../src/templates/singleSaddle.js" /* webpackChunkName: "component---src-templates-single-saddle-js" */)
}

